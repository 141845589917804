export var RouteNames;
(function (RouteNames) {
    RouteNames["HOME"] = "HOME";
    RouteNames["AUTH"] = "AUTH";
    RouteNames["CHECK_AUTH"] = "CHECK_AUTH";
    RouteNames["SETTINGS"] = "SETTINGS";
    RouteNames["ADMIN_SETTING"] = "ADMIN_SETTING";
    RouteNames["USERS"] = "USERS";
    RouteNames["ENTITY"] = "ENTITY";
    RouteNames["CREATE_ENTITY"] = "CREATE_ENTITY";
    RouteNames["EDIT_ENTITY"] = "EDIT_ENTITY";
    RouteNames["CREATE_NODE"] = "CREATE_NODE";
    RouteNames["EDIT_NODE"] = "EDIT_NODE";
})(RouteNames || (RouteNames = {}));
