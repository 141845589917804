import { ApiClient } from "@/services/api";
import { genHash } from "@/store/modals";

export default {
  namespaced: true,
  state: {
    fields: [],
    values: {},
    form: {},
    files: {},
  },
  getters: {
    getFields: (state) => state.fields,
    getForm: (state) => state.form,
    getValues: (state) => state.values,
    getFiles: (state) => state.files
  },
  mutations: {
    clear: (state) => {
      state.values = {}
    },
    setFields: (state, fields) => {
      state.fields = fields
    },
    setField: (state, { field, value }) => {
      if (field.type === 'media') {
        if (state.values[field.code]) {
          delete state.files[state.values[field.code]]
        }
        const hash = genHash()
        state.files[hash] = value
        state.values[field.code] = hash
        state.files = { ...state.files }
      } else {
        state.values[field.code] = value
      }
      state.values = { ...state.values }
    },
    setForm: (state, { fields, values, form }) => {
      state.fields = fields
      state.values = values || {}
      state.form = form
    },
    // editTitleCustomField: (state, { title, index }) => {
    //   state.fields[index].title = title
    //   state.fields = [...state.fields]
    // },
    // editCodeCustomField: (state, { code, index }) => {
    //   state.fields[index].code = code
    //   state.fields = [...state.fields]
    // },
    // removeCustomField: (state, { field, index }) => {
    //   if (field.hash) {
    //     state.fields[index].removed = true
    //     state.fields = [...state.fields]
    //   } else {
    //     state.fields.splice(index, 1)
    //   }
    // },
  },
  actions: {
    save: ({ state, commit }, payload) => {
      let client = ApiClient
      if (payload.mode === 'settings') {
        client.setup(ApiClient.POST, (listRoutes) => listRoutes.admin.saveSettings)
      } else if (state.form && state.form.hash) {
        client.setup(ApiClient.POST, (listRoutes) => listRoutes.admin.editNode)
      } else {
        client.setup(ApiClient.POST, (listRoutes) => listRoutes.admin.createNode)
      }
      commit('setLoader', { loader: 'content', status: true }, { root: true })
      const formData = {
        values: state.values,
        entity: payload.entity,
        ...state.form
      }
      const filesKeys = Object.keys(state.files)
      if (filesKeys.length) {
        formData.files = {}
        filesKeys.forEach((key) => {
          formData.files[key] = state.files[key]
        })
        client.setFormDataWithFiles(formData)
      } else {
        client.setFormData(formData)
      }
      return client
        .request()
        .then(() => {
          commit('setLoader', { loader: 'content', status: false }, { root: true })
        })
    },
    loadNode: ({ commit }, payload) => {
      commit('setLoader', { loader: 'content', status: true }, { root: true })
      return ApiClient
        .setup(ApiClient.GET, (listRoutes) => (listRoutes.admin.node))
        .request({ hash: payload.hash })
        .then((responce) => {
          const node = responce.data.node
          const entity = responce.data.entity
          const values = {}
          Object.values(entity.fields).forEach((field) => {
            values[field.code] = node.fields[field.hash]
          })

          commit('setForm', {
            fields: entity.fields,
            values,
            form: {
              hash: node.hash,
              entity: entity.code
            }
          })
          commit('setLoader', { loader: 'content', status: false }, { root: true })
        })
    },
    loadSettings: ({ commit }) => {
      commit('setLoader', { loader: 'content', status: true }, { root: true })
      return ApiClient
        .setup(ApiClient.GET, (listRoutes) => (listRoutes.admin.getSettings))
        .request()
        .then((responce) => {
          const node = responce.data.node
          const entity = responce.data.entity
          const values = {}
          Object.values(entity.fields).forEach((field) => {
            values[field.code] = node.fields[field.hash]
          })

          commit('setForm', {
            fields: entity.fields,
            values,
            form: {
              hash: node.hash,
              entity: entity.code
            }
          })
        })
        .finally(() => {
          commit('setLoader', { loader: 'content', status: false }, { root: true })
        })
    }
  }
}
