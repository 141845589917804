export var USER_ROLE;
(function (USER_ROLE) {
    USER_ROLE["ANONYMOUSLY"] = "ANONYMOUSLY";
    USER_ROLE["ROLE_NO_CONFIRM"] = "ROLE_NO_CONFIRM";
    USER_ROLE["ROLE_USER"] = "ROLE_USER";
    USER_ROLE["ROLE_MODERATOR"] = "ROLE_MODERATOR";
    USER_ROLE["ROLE_ADMIN"] = "ROLE_ADMIN";
    USER_ROLE["ROLE_SUPER_ADMIN"] = "ROLE_SUPER_ADMIN";
})(USER_ROLE || (USER_ROLE = {}));
export const USER_ROLES_HIERARCHY = {
    [USER_ROLE.ANONYMOUSLY]: [],
    [USER_ROLE.ROLE_NO_CONFIRM]: [USER_ROLE.ANONYMOUSLY],
    [USER_ROLE.ROLE_USER]: [USER_ROLE.ROLE_NO_CONFIRM],
    [USER_ROLE.ROLE_MODERATOR]: [USER_ROLE.ROLE_USER],
    [USER_ROLE.ROLE_ADMIN]: [USER_ROLE.ROLE_MODERATOR],
    [USER_ROLE.ROLE_SUPER_ADMIN]: [USER_ROLE.ROLE_ADMIN],
};
export function getDeepDependRoles(roles, _allRoles = null) {
    const allRoles = _allRoles || new Set();
    if (Array.isArray(roles)) {
        roles.forEach(role => {
            allRoles.add(role);
            getDeepDependRoles(USER_ROLES_HIERARCHY[role], allRoles);
        });
    }
    return allRoles;
}
export function checkAccessByRoles(accessRoles, userRoles) {
    if (!accessRoles.length) {
        return false;
    }
    return accessRoles.some(role => userRoles.includes(role));
}
