import { ApiClient } from "@/services/api";
import router from "../router";
import { getDeepDependRoles } from "@/services/user";

export default {
  namespaced: true,
  state: {
    user: null,
    users: []
  },
  getters: {
    getUser: (state) => state.user,
    getUsers: (state) => state.users,
    userHasRole: (state) => (role) => state.user.nestedRoles.has(role)
  },
  mutations: {
    setUser: (state, payload) => {
      state.user = payload
      state.user.nestedRoles = getDeepDependRoles(payload.roles)

      localStorage.setItem("userToken", payload.token);
      localStorage.setItem("userUsername", payload.username);
      ApiClient.setAutoAuthHeaders()
    },
    removeUser: (state) => {
      state.user = null
      localStorage.removeItem("userToken");
      localStorage.removeItem("userUsername");
    },
    setUsers: (state, payload) => {
      state.users = payload
    }
  },
  actions: {
    loadUsers: ({ commit }) => {
      ApiClient.setup(ApiClient.GET, (t) => t.admin.getUsers).request().then((r) => {
        commit('setUsers', r.data.users)
      })
    },
    logIn: ({ commit }, payload) => {
      ApiClient.setup(ApiClient.GET, (t) => t.auth.login).request(payload).then((r) => {
        commit('setUser', r.data.user)
        router.push({ name: 'HOME' })
      })
    }
  }
}
