<template>
  <div class="file">
    <input type="file" ref="inputFile" hidden @change="emitVal">
    <button class="file__btn" :class="value ? 'file__btn--active' : ''" @click="clickSelect" :icon="''" :type="status">{{ compNameFile }}</button>
  </div>
</template>

<script>
export default {
  name: "InputFile",
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      message: 'Выбрать файл',
      status: 'default'
    }
  },
  props: {
    value: {
      type: File,
      default: null
    },
    category: {
      type: String,
      default: ''
    },
    formats: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 1024, // Kb
    }
  },
  computed: {
    compNameFile () {
      return this.value ? this.value.name : this.message;
    },
  },
  methods: {
    clickSelect () {
      this.$refs.inputFile.click();
    },
    emitVal (e) {
      let file = e.target.files[0];

      if (file.size > this.maxSize * 1024) {
        this.message = 'Файл превышает ' + this.maxSize + ' Кб';
        this.status = 'error';
        return;
      }

      // if (file.type.indexOf(this.category.toLowerCase()) < 0) {
      //   this.message = 'Тип файла не соответствует доступным форматам ' + this.formats;
      //   this.status = 'error';
      //   return;
      // }

      // if ((this.formats.toLowerCase()).indexOf(file.type.substr(file.type.indexOf('/') + 1)) < 0) {
      //   this.message = 'Формат файла не соответствует доступным форматам ' + this.formats;
      //   this.status = 'error';
      //   return;
      // }

      this.status = 'success';
      this.$emit('change', file);
    }
  }
}
</script>

<style scoped lang="less">
.file {

  &__btn {
    width: 100%;
    line-height: 36px;
    padding: 0 12px;
    margin-top: 20px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: start;
    color: #C2C2C2;
    background: white;
    border-radius: 2px;
    outline: none;
    border: 1px solid #EBEEF2;
    cursor: pointer;

    &--active {
      color: #022E40;
    }
  }
}
</style>
