import { ApiClient } from "@/services/api";

export default {
  namespaced: true,
  state: {
    entities: []
  },
  getters: {
    getEntities: state => state.entities,
    getEntityByCode: state => code => state.entities.find(entity => entity.code === code),
    getEntityByHash: state => hash => state.entities.find(entity => entity.hash === hash)
  },
  mutations: {
    setEntities (state, payload) {
      state.entities = payload
    },
    updateEntity (state, payload) {
      state.entities = state.entities.map(entity => payload.code === entity.code ? payload : entity)
    },
    addEntity (state, payload) {
      state.entities.push(payload)
    },
  },
  actions: {
    loadEntities: ({ commit, state }, payload) => {
      if (state.entities.length && !payload.force) {
        return
      }
      return ApiClient
        .setup(ApiClient.GET, (listRoutes) => listRoutes.admin.get)
        .request()
        .then((response) => {
          commit('setEntities', response.data)
        })
    },
    // eslint-disable-next-line no-empty-pattern
    removeEntity: ({}, payload) => {
      return ApiClient
        .setup(ApiClient.POST, (listRoutes) => listRoutes.admin.removeEntity)
        .setFormData({ hash: payload })
        .request()
        .then(() => {
        })
    }
  }
}
