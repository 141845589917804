<template>
  <span
    v-if="field.type === 'media' && media"
  >
    <div
      v-if="isImage"
      style="width: 44px; height: 44px; border-radius: 50%; overflow: hidden; cursor: pointer; margin: 6px"
    >
      <img
        :src="$env.DOMAIN_SERVER_ENV + media"
        alt=""
        style="width: 50px;"
        @click="() => openPicturePreviewModal({ src: $env.DOMAIN_SERVER_ENV + media })"
      >
    </div>
    <span v-else>
      <CircleBtn
        icon="link"
        type="error"
        @click="() => copy()"
      />
    </span>
  </span>
</template>

<script>
import { picturePreviewModal } from "@/mixins/modals";
import CircleBtn from "@/components/ui/CircleBtn";

export default {
  name: "MediaPreview",
  components: {
    CircleBtn
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    media: {
      type: String,
      required: true
    }
  },
  mixins: [picturePreviewModal],
  computed: {
    isImage () {
      return this.media.includes('.jpg') || this.media.includes('.png') || this.media.includes('.png') || this.media.includes('.jpeg')
    }
  },
  methods: {
    copy () {
      const copyText = document.createElement('input')
      copyText.value = this.$env.DOMAIN_SERVER_ENV + this.media
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      alert('Ссылка скопирована в буфер обмена')
    }
  }
}
</script>

<style scoped>

</style>
