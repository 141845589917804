<template>
  <PageLayout>
    <section class="p-16">
      <div class="rcms-header">
        <div class="entity__nav">
          <router-link :to="{ name: 'ENTITY', params: { entity: getEntityData.code } }">
            <CircleBtn icon="upLeft" />
          </router-link>
        </div>

        <h2 class="rcms-header__title">
          Создать запись ({{ getEntityData.title || '-' }})
        </h2>
      </div>
    </section>

    <section>
      <NodeForm />
    </section>
  </PageLayout>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import PageLayout from '@/components/parts/PageLayout'
import NodeForm from "@/components/forms/NodeForm";
import CircleBtn from "@/components/ui/CircleBtn";

export default {
  name: 'CreateNodePage',
  components: {
    PageLayout,
    NodeForm,
    CircleBtn
  },
  computed: {
    ...mapGetters('entities', [
      'getEntityByCode'
    ]),
    ...mapGetters('forms/node', [
      'getFields',
    ]),
    getEntityCode () {
      return this.$route.params.entity
    },
    title () {
      return 'Страницы'
    },
    getEntityData () {
      return this.getEntityByCode(this.$route.params.entity) || {}
    }
  },
  mounted () {
    this.setForm({
      fields: this.getEntityData.fields,
      form: {
        entity: this.$route.params.entity
      }
    })
  },
  methods: {
    ...mapMutations('forms/node', [
      'setForm',
    ])
  }
}
</script>
