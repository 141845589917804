<template>
    <div class="number-input__wrapper">
        <div v-if="label" class="number-input__label">
            {{ label }}
        </div>
        <div  class="number-input">
            <div
                :class="{ 'number-input__operation--disabled': isLockMinus }"
                class="number-input__operation"
                @click="() => decrement()"
            >
                <IconRCMS icon="minus" color="#D94E67" />
            </div>
            <input
                :value="current"
                type="text"
                @input="(v) => formateValue(v)"
                class="number-input__value"
            />
            <div
                :class="{ 'number-input__operation--disabled': isLockMinus }"
                class="number-input__operation"
                @click="() => increment()"
            >
                <IconRCMS icon="plus" color="#D94E67" />
            </div>
        </div>
    </div>
</template>

<script>
import IconRCMS from "./../ui/Icon.vue"

export default {
    name: "NumberInput",
    components: {
        IconRCMS,
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            default: '',
        },
        max: {
            type: Number,
            default: Number.POSITIVE_INFINITY,
            required: false,
        },
        min: {
            type: Number,
            default: Number.NEGATIVE_INFINITY,
            required: false,
        },
        step: {
            type: Number,
            default: 1
        }
    },
    computed: {
        isLockPlus() {
            return this.max <= Number(this.value.toFixed(2));
        },
        isLockMinus() {
            return this.min >= Number(this.value.toFixed(2));
        },
        current() {
            return Number(this.value.toFixed(2));
        }
    },
    methods: {
        formateValue (v) {
          this.$emit('change', v.target.value === '' ? '' : Number(v.target.value))
        },
        increment() {
            if (!this.isLockPlus) {
                this.$emit("change", this.value + this.step);
            }
        },
        decrement() {
            if (!this.isLockMinus) {
                this.$emit("change", this.value - this.step);
            }
        }
    }
}
</script>

<style scoped lang="less">


@width: 32px;
@borderColor: #000000;

.number-input {
    display: inline-flex;
    align-items: stretch;
    border: @sizeBorderDefault solid @borderColor;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    height: @width;

    &__wrapper {
        display: inline-flex;
        flex-direction: column;
    }

    &__value {
        height: @width;
        width: 80px;
        padding: 0;
        text-align: center;
        outline: none;
        color: @cBaseThree;
        border-bottom: none;
        border-top: none;
        background: none;
        border-right: @sizeBorderDefault solid @borderColor;
        border-left: @sizeBorderDefault solid @borderColor;
        font-family: @ffOne;
    }

    &__operation {
        display: flex;
        justify-content: center;
        align-items: center;
        width: @width;
        height: @width;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            background-color:  #ffc1cc;
        }
    }

    &__label {
        font-size: 14px;
        line-height: 14px;
        padding: 0 14px;
        padding-bottom: 2px;
        font-family: @ffOne;
    }
}
</style>
