import { ApiClient } from "@/services/api";

export default {
  namespaced: true,
  state: {
    nodes: [],
    relateNodes: [],
    count: 0,
  },
  getters: {
    getNodes: state => state.nodes,
    getRelateNodes: state => state.relateNodes,
    getCount: state => state.count,
  },
  mutations: {
    setNodes (state, payload) {
      state.nodes = payload
    },
    setRelateNodes (state, payload) {
      state.relateNodes = payload
    },
    setCount (state, payload) {
      state.count = payload
    },
  },
  actions: {
    loadNodes: ({ commit }, { code, params }) => {
      commit('setLoader', { loader: 'content', status: true }, { root: true })
      return ApiClient
        .setup(ApiClient.GET, (listRoutes) => listRoutes.admin.nodes)
        .request({ code, ...params })
        .then((responce) => {
          commit('setCount', responce.data.count)
          commit('setNodes', responce.data.nodes)
          commit('setRelateNodes', responce.data.relateNodes)
          commit('setLoader', { loader: 'content', status: false }, { root: true })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    removeNode: ({}, payload) => {
      return ApiClient
        .setup(ApiClient.POST, (listRoutes) => listRoutes.admin.removeNode)
        .setFormData({ hash: payload.hash })
        .request()
    }
  }
}
