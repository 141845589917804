<template>
  <svg
    :fill="color"
    :width="getSize"
    :height="getSize"
    v-html="iconSVG"
    class="icon"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ></svg>
</template>

<script>
import('vue-unicons/dist/icons')
// import { uniLayerGroupMonochrome, uniCarWash } from 'vue-unicons/dist/icons'
// console.log(uniLayerGroupMonochrome, uniCarWash)

const icons = {
  name: () => import('@/const/icons/name'),
  plus: () => import('@/const/icons/plus'),
  minus: () => import('@/const/icons/minus'),
  edit: () => import('@/const/icons/edit'),
  check: () => import('@/const/icons/check'),
  text: () => import('@/const/icons/text'),
  subject: () => import('@/const/icons/subject'),
  number: () => import('@/const/icons/number'),
  cog: () => import('@/const/icons/cog'),
  trash: () => import('@/const/icons/trash'),
  angle: () => import('@/const/icons/angle'),
  enumerate: () => import('@/const/icons/enumerate'),
  date: () => import('@/const/icons/date'),
  boolean: () => import('@/const/icons/boolean'),
  relation: () => import('@/const/icons/relation'),
  media: () => import('@/const/icons/media'),
  upLeft: () => import('@/const/icons/up-left'),
  rank: () => import('@/const/icons/rank'),
  link: () => import('@/const/icons/link'),
}

export default {
  name: 'IconRCMS',
  props: {
    size: {
      default: 'medium'
    },
    icon: {
      default: '',
      type: String
    },
    color: {
      default: '#000000',
      type: String
    }
  },
  data() {
    return {
      iconSVG: null
    }
  },
  computed: {
    getSize() {
      switch (this.size) {
        case 'medium':
          return 20
        case 'mini':
          return 18
      }
      return 20
    }
  },
  mounted () {
    this.setIcon()
  },
  methods: {
    setIcon () {
      if (!icons[this.icon]) {
        return
      }
      icons[this.icon]()
        .then((v) => {
          this.iconSVG = v.default
        })
    }
  },
  watch: {
    icon: {
      handler () {
        this.setIcon()
      }
    }
  }
}

</script>

<style lang="less" scoped>
.icon {
  transition: 0.2s;
}
</style>
