import users from './users'
import entities from './entities'
import nodes from './nodes'
import forms from './forms'
import modals from './modals'

export default {
  namespaced: true,
  state: {
    loaders: {
      content: false
    }
  },
  getters: {
    getLoaderContent: (state) => state.loaders.content
  },
  mutations: {
    setLoader: (state, payload) => {
      state.loaders[payload.loader] = payload.status
    }
  },
  modules: {
    users,
    entities,
    nodes,
    forms,
    modals
  }
}
