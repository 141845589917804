import { mapMutations } from "vuex";
import { TYPE_FIELD } from "@/const";

import FieldConfig from "@/components/modals/FieldConfig/Example";
import RelationFieldConfig from "@/components/modals/FieldConfig/RelationFieldConfig";
import NumberFieldConfig from "@/components/modals/FieldConfig/NumberFieldConfig";
import TextFieldConfig from "@/components/modals/FieldConfig/TextFieldConfig";

import PicturePreview from "@/components/modals/PicturePreview";
import HelpModal from "@/components/modals/HelpModal";

export const fieldConfigModal = {
  methods: {
    ...mapMutations('modals', [
      'openModal'
    ]),
    openFieldConfigModal (params) {
      if (params.field.type === TYPE_FIELD.RELATION) {
        return this.openModal({
          component: RelationFieldConfig,
          params
        })
      }
      if (params.field.type === TYPE_FIELD.NUMBER) {
        return this.openModal({
          component: NumberFieldConfig,
          params
        })
      }
      if (params.field.type === TYPE_FIELD.TEXT) {
        return this.openModal({
          component: TextFieldConfig,
          params
        })
      }
      this.openModal({
        component: FieldConfig,
        params
      })
    }
  }
}

export const picturePreviewModal = {
  methods: {
    ...mapMutations('modals', [
      'openModal'
    ]),
    openPicturePreviewModal (params) {
      this.openModal({
        component: PicturePreview,
        params
      })
    }
  }
}

export const helpModal = {
  methods: {
    ...mapMutations('modals', [
      'openModal'
    ]),
    openHelpModal (params) {
      this.openModal({
        component: HelpModal,
        params
      })
    }
  }
}
