// @ts-ignore
import { RouteNames } from "@/router/routes";
export default {
    install(Vue) {
        Vue.prototype.$routeNames = RouteNames;
        Vue.prototype.$env = {
            DOMAIN_SERVER_ENV: process.env.DOMAIN_SERVER_ENV,
            DOMAIN_APP_ENV: process.env.DOMAIN_APP_ENV
        };
    }
};
