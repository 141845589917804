<template>
  <PageLayout>
    <section v-if="getEntityData" class="p-16">
      <div class="rcms-header">
        <div class="entity__nav">
          <router-link :to="{ name: 'ENTITY', params: { entity: getEntityData.code } }">
            <CircleBtn icon="upLeft" />
          </router-link>
        </div>

        <h2 class="rcms-header__title">
          Редактировать запись ({{ getEntityData.title }})
        </h2>
      </div>
    </section>

    <section>
      <NodeForm v-if="loaded" mode="edit" />
    </section>
  </PageLayout>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import PageLayout from '@/components/parts/PageLayout'
import NodeForm from "@/components/forms/NodeForm";
import CircleBtn from "@/components/ui/CircleBtn";

export default {
  name: 'EditNodePage',
  components: {
    PageLayout,
    NodeForm,
    CircleBtn
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters('entities', [
      'getEntityByCode'
    ]),
    ...mapGetters('forms/node', [
      'getFields'
    ]),
    getEntityCode () {
      return this.$route.params.entity
    },
    title () {
      return 'Страницы'
    },
    getEntityData () {
      return this.getEntityByCode(this.$route.params.entity)
    }
  },
  mounted() {
    this.loadNode({ hash: this.$route.params.node })
      .then(() => {
        this.loaded = true
      })
  },
  methods: {
    ...mapActions('forms/node', [
      'loadNode'
    ]),
    ...mapMutations('forms/entity', [
      'setField'
    ])
  }
}
</script>

<style lang="less" scoped>
</style>
