<template>
  <div class="sidebar">
    <router-link :to="{ name: $routeNames.HOME}">
      <div class="sidebar__cms-name">
          RCMS
      </div>
    </router-link>
    <div class="rcms-divider-h" />

    <div class="sidebar__menu">
      <div v-if="userHasRole(userRoles.ROLE_ADMIN)" class="sidebar__menu-name">
        <div class="sidebar__menu-name__title">
          Сущности
        </div>
        <router-link
          v-if="userHasRole(userRoles.ROLE_ADMIN)"
          :to="{ name: $routeNames.CREATE_ENTITY }"
        >
          <div class="sidebar__menu-name__new-action" @click="() => emptyForm()">
            <CircleBtn icon="plus" />
          </div>
        </router-link>
      </div>
      <div v-if="userHasRole(userRoles.ROLE_ADMIN)" class="rcms-divider-h" />
      <div class="sidebar__submenu">
        <span v-if="userHasRole(userRoles.ROLE_ADMIN)">Pins</span>
        <router-link
          v-for="entity in getEntitiesWithPin"
          :key="entity.code"
          :to="{ name: $routeNames.ENTITY, params: { entity: entity.code } }"
        >
          <div class="sidebar__submenu__item">
            {{ entity.title }}
          </div>
        </router-link>
        <div v-if="userHasRole(userRoles.ROLE_ADMIN)">
          <span>No-Pins</span>
          <router-link
            v-for="entity in getEntitiesWithoutPin"
            :key="entity.code"
            :to="{ name: $routeNames.ENTITY, params: { entity: entity.code } }"
          >
            <div class="sidebar__submenu__item">
              {{ entity.title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="rcms-divider-h" />

    <div class="sidebar__menu-exit">
      <router-link v-if="userHasRole(userRoles.ROLE_ADMIN)" :to="{ name: $routeNames.USERS}">
        <div class="sidebar__menu-exit__btn">
          Пользователи
        </div>
      </router-link>
      <router-link :to="{ name: $routeNames.SETTINGS}">
        <div class="sidebar__menu-exit__btn">
          Настройки
        </div>
      </router-link>
      <div @click="() => logOut()" class="sidebar__menu-exit__btn">
        Выйти
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CircleBtn from "./../ui/CircleBtn.vue"
import { user } from "@/mixins/user"

export default {
  name: "MainMenu",
  mixins: [
    user
  ],
  components: {
    CircleBtn
  },
  computed: {
    ...mapGetters('entities', [
      'getEntities'
    ]),
    getEntitiesWithPin () {
      return this.getEntities.filter((item) => item.pin)
    },
    getEntitiesWithoutPin () {
      return this.getEntities.filter((item) => !item.pin)
    }
  },
  methods: {
    ...mapMutations('users', [
      'removeUser'
    ]),
    ...mapMutations('forms/entity', [
      'clear'
    ]),
    logOut () {
      this.removeUser('removeUser')
      this.$router.push({ name: 'AUTH' })
    },
    emptyForm () {
      this.clear()
    }
  }
}
</script>

<style scoped lang="less">
.sidebar {
  display: flex;
  flex-direction: column;
  width: 300px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  font-family: @ffOne;
  background-color: #fafafa;

  &__cms-name {
    color: @cBaseFour;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
  }

  &__menu {
    flex: 1;
  }

  &__menu-exit {
    font-weight: 700;
    font-size: 16px;
    line-height: 44px;
    margin-top: 16px;
    cursor: pointer;

    &__btn {
      transition: 0.2s;
      color: #000000;

      &:hover {
        color: #D94E67;
      }
    }
  }

  &__menu-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      &__title {
        font-weight: 700;
        font-size: 24px;
      }
    }

  &__submenu {
    padding-top: 10px;

    &__item {
      color: #000000;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      transition: 0.2s;

      &:hover {
        color: @cBaseFour;
      }
    }
  }
}
</style>
