<template>
  <PageLayout>
    <section class="p-16">
      <h2>
        Пользователи
      </h2>
    </section>
    <section class="p-16">
      <div class="rcms-table">
        <div class="rcms-table__header">
          <div class="rcms-table__header__column rcms-table__header__column--default">
            #
          </div>
          <div class="rcms-table__header__column">
            Пользователь
          </div>
        </div>
        <div v-for="(user, index) in getUsers" :key="user.id" class="rcms-table__row">
          <div class="rcms-table__column rcms-table__column--default">
            {{ index + 1 }}
          </div>
          <div class="rcms-table__column" >
            {{ user.username }}
          </div>
          <div class="rcms-table__row__nav">
            <router-link :to="{ name: 'EDIT_NODE', params: { entity: $route.params.entity, id: user.id } }">
              <CircleBtn icon="edit" />
            </router-link>
<!--            <CircleBtn @click="() => removeNodeHandler({ hash: node.hash })" icon="trash" type="error"/>-->
          </div>
        </div>
        <div v-if="!getUsers || !getUsers.length">Ничего нет</div>
      </div>
    </section>
  </PageLayout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageLayout from '@/components/parts/PageLayout'
import CircleBtn from "@/components/ui/CircleBtn";

export default {
  name: 'AdminSettingsPage',
  components: {
    PageLayout,
    CircleBtn
  },
  computed: {
    ...mapGetters('users', [
      'getUsers'
    ])
  },
  mounted () {
    this.loadUsers()
  },
  methods: {
    ...mapActions('users', [
      'loadUsers'
    ]),
  }
}
</script>

<style>
</style>
