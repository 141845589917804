import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../components/pages/Home.vue';
import CreateEntityPage from '../components/pages/entities/CreateEntity.vue';
import EditEntityPage from '../components/pages/entities/EditEntity.vue';
import EntityPage from '../components/pages/entities/Entity.vue';
import CreateNodePage from '../components/pages/entities/CreateNode.vue';
import EditNodePage from '../components/pages/entities/EditNode.vue';
import AuthPage from '../components/pages/Auth.vue';
import AdminSettings from '../components/pages/admin/AdminSettings.vue';
import SettingsPage from '../components/pages/Settings.vue';
import UsersPage from '../components/pages/Users.vue';
import { RouteNames } from '../router/routes';
import { USER_ROLE } from '../services/user';
Vue.use(VueRouter);
const DEFAULT_REDIRECT = 'AUTH';
const routes = [
    {
        path: '/',
        name: RouteNames.HOME,
        component: HomePage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
        }
    },
    {
        path: '/entity/create',
        name: RouteNames.CREATE_ENTITY,
        component: CreateEntityPage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
        }
    },
    {
        path: '/entity/:entity',
        name: RouteNames.ENTITY,
        component: EntityPage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
        }
    },
    {
        path: '/entity/:entity/create',
        name: RouteNames.CREATE_NODE,
        component: CreateNodePage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
        }
    },
    {
        path: '/entity/:entity/:node/edit',
        name: RouteNames.EDIT_NODE,
        component: EditNodePage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
        }
    },
    {
        path: '/entity/:entity/edit',
        name: RouteNames.EDIT_ENTITY,
        component: EditEntityPage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
        }
    },
    {
        path: '/admin/settings',
        name: RouteNames.ADMIN_SETTING,
        component: AdminSettings,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
            redirect: RouteNames.HOME
        }
    },
    {
        path: '/settings',
        name: RouteNames.SETTINGS,
        component: SettingsPage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_MODERATOR
            ],
            redirect: RouteNames.HOME
        }
    },
    {
        path: '/users',
        name: RouteNames.USERS,
        component: UsersPage,
        meta: {
            accessRoles: [
                USER_ROLE.ROLE_ADMIN
            ],
            redirect: RouteNames.HOME
        }
    },
    {
        path: '/auth',
        name: RouteNames.AUTH,
        component: AuthPage,
        meta: {
            onlyAccessRoles: true,
            accessRoles: [
                USER_ROLE.ANONYMOUSLY
            ],
            redirect: RouteNames.HOME
        }
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router;
