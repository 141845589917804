<template>
    <div
        class="checkbox__wrapper"
        :class="{ 'checkbox__wrapper--disabled': disabled }"
    >
        <div
            class="checkbox"
            @click="() => toggle()">
            <IconRCMS v-if="value" icon="check" color="#D94E67" size="mini" />
        </div>
        <div v-if="label" class="checkbox__label">
            {{ label }}
        </div>
    </div>
</template>

<script>
import IconRCMS from './Icon.vue'

export default {
    name: "CheckboxRCMS",
    components: {
        IconRCMS
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            default: '',
            type: String
        }
    },
    methods: {
        toggle () {
            if (this.disabled) {
                return
            }
            this.$emit("change", !this.value)
        }
    }
}
</script>

<style scoped lang="less">
.checkbox {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid @cBaseThree;
    transition: 0.2s;
    box-sizing: border-box;
    cursor: pointer;

    &__wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        box-sizing: border-box;

        &--disabled {
            opacity: 0.5;
        }
    }

    &__label {
        font-size: 18px;
        line-height: 14px;
        padding-top: 1px;
        font-family: @ffOne;
    }
}

</style>
