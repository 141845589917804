<template>
    <div class="label-panel__wrapper">
        <div v-if="label" class="label-panel__title">
            {{ label }}
        </div>
        <div class="label-panel">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "LabelPanel",
    props: {
        label: {
            type: String,
            default: 'Поля',
        }
    }
}

</script>

<style scoped lang="less">
.label-panel {
    min-height: 60px;
    background-color: @cBaseTwo;
    border-radius: 4px;

    &__title {
        font-family: @ffOne;
        font-size: 14px;
        padding-bottom: 2px;
        padding-left: 14px;
    }
}
</style>
