<template>
    <div class="add-field__wrapper">
        <div v-if="showTypes" class="add-field__types">
            <div
                v-for="t in types"
                :key="t"
                class="add-field__type"
                @click="() => addField(t)"
            >
                <BadgeIcon :icon="dataTypes[t].icon" :type="dataTypes[t].color" />
                <span>{{ dataTypes[t].title }}</span>
            </div>
        </div>
        <div v-else class="add-field__action">
            <CircleBtn @click="() => toggleShowTypes()" icon="plus" />
        </div>
    </div>
</template>

<script>
import CircleBtn from "./../ui/CircleBtn.vue"
import BadgeIcon from './BadgeIcon.vue'
import { listTypeField, namedListTypeField } from "../../const";

export default {
    name: "AddField",
    components: {
        CircleBtn,
        BadgeIcon
    },
    data () {
        return {
            showTypes: false
        }
    },
    computed: {
        types () {
            return listTypeField
        },
        dataTypes () {
          return namedListTypeField
        }
    },
    methods: {
        toggleShowTypes () {
            this.showTypes = !this.showTypes
        },
        addField (t) {
            this.$emit('add', t);
            this.toggleShowTypes();
        }
    }
}

</script>

<style scoped lang="less">
.add-field {

    &__action {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
    }

    &__types {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 16px;
        grid-gap: 16px;
    }

    &__type {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 12px;
        border-radius: 4px;
        background-color: @cBaseOne;
        font-family: @ffOne;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            background-color: #E1E1E1;
        }
    }
}
</style>
