<template>
    <div
        class="form-control"
        :class="'form-control--' + size"
    >
        <div
            v-if="label"
            class="form-label"
            :class="[ labelUp ? 'active' : '', status ]"
            @click="setForceLabel"
        >
            {{ label}}
        </div>
        <div class="form-input-wrapper">
            <input
                class="form-input"
                :class="[ status ]"
                :disabled="disabled"
                :value="value"
                :type="type"
                @input="emitVal"
                @focus="setForceLabel(true)"
                @blur="setForceLabel(false)"
                ref="input"
            >
            <div
                class="icon"
                :class="status">
                <!--                <font-awesome-icon v-if="getIconStatus" :icon="getIconStatus" />-->
            </div>
        </div>
        <span class="dialog" v-if="message">{{ message }}</span>
    </div>
</template>

<script>

export default {
    name: "CustomInput",
    data() {
        return {
        forceLabel: false
        }
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        status: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        size: {
            type: String,
            default: 'medium'
        }
    },
    computed: {
        getIconStatus: function() {
            if (this.status === 'success') {
                return 'check'
            } else if (this.status === 'error') {
                return 'exclamation-circle'
            } else {
                return false;
            }
        },
        labelUp: function() {
            return (this.value && (this.value.length > 0)) || this.forceLabel;
        }
    },
    methods: {
        emitVal: function(e) {
            this.$emit('input', e.target.value);
        },
        setForceLabel: function(v = true) {
            if (v) this.$refs.input.focus();
            this.forceLabel = v;
        }
    }
}
</script>

<style scoped lang="less">
.form-control {
    @padding-form: 12px 14px 10px;
    position: relative;

    .form-label {
        position: absolute;
        padding: 0 14px;
        font-family: @ffOne;
        font-size: 16px;
        color: #C2C2C2;
        transition: 0.2s ease-in-out;
        top: 8px;
        z-index: 1;
        user-select: none;
        cursor: text;

        &.active {
            font-size: 14px;
            top: -20px;
            color: @cBaseThree;
        }

        &.success {
            color: #75dc75;
        }

        &.error {
            color: #cc524a;
        }
    }

    .form-input {
        width: 100%;
        color: @cBaseThree;
        padding: 0 12px;
        line-height: 36px;
        box-sizing: border-box;
        transition: 0.2s;
        font-family: @ffOne;
        font-size: 14px;
        background: white;
        border-radius: 2px;
        outline: none;
        border: @sizeBorderDefault solid @cBaseTwo;
        border-bottom: @sizeBorderDefault solid @cBaseTwo;

        &:focus {
            border: @sizeBorderDefault solid @cBaseThree;
        }

        &-wrapper {
            position: relative;
            margin-top: 20px;
        }

        &::placeholder {
            color: #bfbfbf;
        }

        &.success {
            border-bottom: @sizeBorderDefault solid #75dc75;

        &:focus {
            border: @sizeBorderDefault solid #75dc75;
            border-bottom: @sizeBorderDefault solid #75dc75;
        }
        }

        &.error {
            border-bottom: @sizeBorderDefault solid #cc524a;

            &:focus {
                border: @sizeBorderDefault solid #cc524a;
                border-bottom: @sizeBorderDefault solid #cc524a;
            }
        }

        &[disabled] {
            border-bottom: @sizeBorderDefault solid #777;
        }
    }

    & .icon {
        position: absolute;
        top: 0;
        right: 0;
        padding: @padding-form;

        &.success {
            color: #75dc75;
        }

        &.error {
            color: #cc524a;
        }
    }

    & .dialog {
        font-family: @ffOne;
        font-size: 14px;
        padding: 5px 10px;
        color: #cc524a;
        font-weight: bold;
    }

    &--mini {
        .form-input {
            padding: 0 14px;
            line-height: 30px;
            border-radius: 4px;
        }

        .form-label {
            top: 24px;

            &.active {
                top: -2px;
                color: @cBaseThree;
            }
        }
    }
}
</style>
