<template>
  <PageLayout>
    <section class="p-16">
      Добро пожаловать!
    </section>
    <section v-if="userHasRole(userRoles.ROLE_ADMIN)" class="p-16">
      ADMIN

      <RouterLink :to="{ name: 'ADMIN_SETTING' }">
        Настройки
      </RouterLink>
    </section>
  </PageLayout>
</template>

<script>
import PageLayout from '../parts/PageLayout'
import { user } from "@/mixins/user";

export default {
  name: 'HomePage',
  mixins: [
    user
  ],
  components: {
    PageLayout
  },
  data() {
    return {
      selectedOption: "",
    };
  },
  methods: {
    customLabel (option) {
      return (`${option.name} - ${option.value}`);
    }
  },
  computed: {
  },
}
</script>

<style>
</style>
